html,
body,
#root {
  height: 100%;
  margin: 0;
  font-size: 16px;
  font-family: "Averta", "Helvetica Neue", Helvetica, Arial, "Lucida Grande",
  sans-serif;
  color: #333333;
  line-height: 24px;
  position: relative;
  z-index: 10001;
  font-weight: 300;
  overflow: hidden;
}

#footer-mobile {
  position: relative;
  z-index: 10002;
  box-shadow: -1px 0 6px 0 rgba(0, 0, 0, 0.15);
  height: 100%;
}

body {
  background: #ECF7FB;
}

#root {
  overflow: scroll;
  background: url('./App/img/footer-trees.svg') center 100% no-repeat, url('./App/img/clouds1.svg') calc(50% - 50px) 120px repeat-x, url('./App/img/clouds2.svg') calc(50% + 0px) 120px no-repeat;
  padding-bottom: 48px;
}

@media (max-width: 961px) {
  body.no-footer #root {
    padding-bottom: 0;
  }
}

a {
  font-size: 14px;
  color: #00a0ff;
}

@media (min-width: 961px) {
  a:hover {
    text-decoration: none;
  }
}

* {
  box-sizing: border-box;
  font-family: "Averta", "Helvetica Neue", Helvetica, Arial, "Lucida Grande",
  sans-serif;
}

h1, h2 {
  font-weight: 300;
}

h1 strong, h2 strong {
  color: #FF5253;
}

h1 {
  font-size: 44px;
  line-height: 48px;
  color: #212f49;
}

h2 {
  font-size: 28px;
  line-height: 42px;
  color: #212F49;
}

small {
  line-height: 12px;
  font-size: 12px;
}

p {
  color: #737373;
  margin-bottom: 0;
  font-weight: 300;
}

ul {
  margin-top: 8px;
  margin-bottom: 32px;
  margin-left: 0;
  padding-left: 24px;
}

li {
  padding-left: 6px;
}
